import { dateService, objectService, RequestClient } from '@lerna-core';

export class GoogleSheetLoggerClient extends RequestClient<any, any, any> {
  requestParameters: any;
  path = '';

  constructor(path: string, requestParams: any) {
    super(
      'https://script.google.com/macros/s/',
      '',
      {
        'Content-Type': `multipart/form-data`,
      },
      { withCredentials: false }
    );

    this.path = path;
    this.requestParameters = new FormData();

    if (!requestParams.formGoogleSheetName) {
      const date = new Date();
      this.requestParameters.append(
        'formGoogleSheetName',
        `${dateService.formatMonthNumberToString(
          date.getMonth()
        )} ${date.getFullYear()}`
      );
    }

    const flatRequestParams = objectService.deepFlattenToObject(requestParams);
    for (const key in flatRequestParams) {
      this.requestParameters.append(key, flatRequestParams[key]);
    }
  }
}
