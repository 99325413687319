import {
  cookieService,
  MindboxEventName,
  Nullable,
  publicConfig,
  RegionsEnum,
  UserAggregateModel,
} from '@lerna-core';
import {
  segmentsBy,
  segmentsKg,
  segmentsKz,
  segmentsRu,
  segmentsUz,
} from './wheelOfFortune.meta';
import { FortuneSegmentModel } from './wheelOfFortune.model';
import { WHEEL_OF_FORTUNE_COOKIE_NAME } from './wheelOfFortune.constants';

export const getMindboxEventName = (): MindboxEventName => {
  if (publicConfig.LOCATION === 'dev') {
    return MindboxEventName.wheelOfFortuneLocal;
  }

  switch (publicConfig.REGION) {
    case RegionsEnum.by:
      return MindboxEventName.wheelOfFortuneBy;
    case RegionsEnum.uz:
      return MindboxEventName.wheelOfFortuneUz;
    case RegionsEnum.kz:
      return MindboxEventName.wheelOfFortuneKz;
    case RegionsEnum.kg:
      return MindboxEventName.wheelOfFortuneKg;
    case RegionsEnum.ru:
    default:
      return MindboxEventName.wheelOfFortuneRu;
  }
};

export const getMindboxEventValue = (val: string): Record<string, string> => {
  switch (publicConfig.REGION) {
    case RegionsEnum.by:
      return { winningWheelFortuneBy: val };
    case RegionsEnum.uz:
      return { winningWheelFortuneUz: val };
    case RegionsEnum.kz:
      return { winningWheelFortuneKz: val };
    case RegionsEnum.kg:
      return { winningWheelFortuneKG: val };
    case RegionsEnum.ru:
    default:
      return { winningWheelFortune: val };
  }
};

export const getSegments = (): FortuneSegmentModel[] => {
  switch (publicConfig.REGION) {
    case RegionsEnum.by:
      return segmentsBy;
    case RegionsEnum.ru:
      return segmentsRu;
    case RegionsEnum.kz:
      return segmentsKz;
    case RegionsEnum.kg:
      return segmentsKg;
    case RegionsEnum.uz:
      return segmentsUz;
    default:
      return [];
  }
};

export const getCanShowPopup = (
  user: Nullable<UserAggregateModel>
): boolean => {
  const hideWheelCookie = getWheelCookieHide();

  return (
    (publicConfig.REGION === RegionsEnum.ru ||
      publicConfig.REGION === RegionsEnum.by ||
      publicConfig.REGION === RegionsEnum.kz ||
      publicConfig.REGION === RegionsEnum.kg ||
      publicConfig.REGION === RegionsEnum.uz) &&
    !hideWheelCookie &&
    !!user?.career &&
    !(
      user.career.isBonusPointsHiddenOnListing ||
      user.career.isPricesHiddenOnListingCards ||
      (user.career.isPersonalDataFillOptional && !user.personal.isFulfilled) ||
      user.career.isWithoutPromoBanner ||
      user.career.isListingWithBenefitsOnly ||
      user.career.isListingWithSubscriptionsOnly
    )
  );
};

export const setWheelCookie = (hide: boolean) => {
  cookieService.setCookie({
    name: WHEEL_OF_FORTUNE_COOKIE_NAME,
    // количество часов до конца дня
    days: Math.floor(((24 - new Date().getHours()) / 24) * 100) / 100,
    val: JSON.stringify({ hide }),
  });
};

export const getWheelCookieHide = (): boolean => {
  const cookie = cookieService.getCookie(WHEEL_OF_FORTUNE_COOKIE_NAME);

  if (cookie) {
    return JSON.parse(cookie)?.hide || false;
  }

  return false;
};

export const getGoogleSheetRequestParams = (
  segment: FortuneSegmentModel,
  user: Nullable<UserAggregateModel>
): Record<string, string> => {
  const requestParams = {
    region: publicConfig.REGION,
    url: window.location.href,
    prize_id: segment.id,
    prize_title: segment.text,
  } as Record<string, string>;

  if (user?.personal) {
    Object.assign(requestParams, {
      user_name: `${user.personal.firstName} ${user.personal.lastName}`,
      email: user.personal.email,
      phone: user.personal.phone,
    });
  }

  return requestParams;
};
