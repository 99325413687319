import { GoogleSheetLoggerClient } from './googleSheetLogger.client';

export const googleSheetLoggerClientProvider = async (
  path: string,
  requestParams: any
): Promise<void> => {
  const response = new GoogleSheetLoggerClient(path, requestParams).post();

  return response as unknown as Promise<void>;
};
